import { Link } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";

import illustrationIntro from "../assets/images/illustration-intro.svg";
import ZohoForm from "./form/ZohoForm";
import { useEffect, useRef } from "react";

const Hero = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);

  return (
    <motion.section
      ref={ref}
      variants={{
        hidden: { opacity: 0, x: -75 },
        visible: { opacity: 1, x: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      {/* Flex Container */}
      <div className="container flex flex-col-reverse items-center px-6 mx-auto mt-10 space-y-0 md:space-y-0 md:flex-row">
        {/* Left Item */}
        <div className="flex flex-col mb-32 space-y-6 md:w-1/2">
          <h1 className="max-w-md text-4xl font-bold text-center md:text-5xl md:text-left">
            Free Forex Course
          </h1>
          <p className="">Trade with $100 On Us</p>
          <p className="max-w-sm text-center text-darkGrayishBlue md:text-left">
            Profit Like a Pro: Shortcut to Forex Riches Exposed
          </p>

          <p className="">
            From zero to hero: Your forex success story starts here!
          </p>

          <div className="flex justify-center md:justify-start">
            <Link
              to="#"
              className="p-3 px-6 pt-2 text-white bg-primary rounded-full baseline hover:bg-brightRedLight"
            >
              Start Your Forex Journey Now
            </Link>
          </div>
        </div>
        {/* Image */}
        <div className="md:w-1/2">
          {/* <img src={illustrationIntro} alt="" /> */}

          <ZohoForm />
        </div>
      </div>
    </motion.section>
  );
};

export default Hero;
