import { Link } from "react-router-dom";

import avatarAnisha from "../assets/images/avatar-anisha.png";
import avatarAli from "../assets/images/avatar-ali.png";
import avatarRichard from "../assets/images/avatar-richard.png";
import { Rating } from "@smastrom/react-rating";
import { useEffect, useRef, useState } from "react";
import CustomRating from "./CustomRating";
import { useAnimation, useInView, motion } from "framer-motion";

const Testimonial = () => {
  const [rating, setRating] = useState(5);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);

  return (
    <motion.section
      id="testimonials"
      ref={ref}
      variants={{
        hidden: { opacity: 0, y: 200 },
        visible: { opacity: 1, y: 0 },
      }}
      initial="hidden"
      animate={mainControls}
      transition={{ duration: 0.5, ease: "easeOut" }}
    >
      {/* Container to heading and testm blocks */}
      <div className="max-w-6xl px-5 mx-auto mt-32 text-center">
        {/* Heading */}

        <div className="flex gap-4  justify-center">
          <div className="w-20  ">
            <img src="/img/google.png" alt="" />
          </div>

          <Rating
            style={{ maxWidth: 100 }}
            value={rating}
            onChange={setRating}
            isDisabled
          />
        </div>

        {/* Testimonials Container */}
        <div className="flex flex-col mt-24 md:flex-row md:space-x-6 justify-center">
          {/* Testimonial 1 */}
          <div className="flex flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:w-1/3">
            <img src={avatarAnisha} className="w-16 -mt-14" alt="" />
            <div className="w-20">
              <CustomRating />
            </div>
            <h5 className="text-lg font-bold">Marco Del Vecchio</h5>
            <p className="text-sm text-darkGrayishBlue">
              “I made my first profit in just two weeks!.”
            </p>
          </div>

          {/* Testimonial 2 */}
          <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
            <img src={avatarAli} className="w-16 -mt-14" alt="" />
            <div className="w-20">
              <CustomRating />
            </div>
            <h5 className="text-lg font-bold">Sarah M</h5>
            <p className="text-sm text-darkGrayishBlue">
              “This course gave me the confidence to start trading..”
            </p>
          </div>

          {/* Testimonial 3 */}
          {/* <div className="hidden flex-col items-center p-6 space-y-6 rounded-lg bg-veryLightGray md:flex md:w-1/3">
            <img src={avatarRichard} className="w-16 -mt-14" alt="" />
            <h5 className="text-lg font-bold">Richard Watts</h5>
            <p className="text-sm text-darkGrayishBlue">
              “Manage has supercharged our team's workflow. The ability to
              maintain visibility on larger milestones at all times keeps
              everyone motivated.”
            </p>
          </div> */}
        </div>
        {/* Button */}
        <div className="my-16">
          <Link
            to="#"
            className="p-3 px-6 pt-2 text-white bg-primary rounded-full baseline hover:bg-brightRedLight"
          >
            Claim Your Risk Free Course
          </Link>
        </div>
      </div>
    </motion.section>
  );
};

export default Testimonial;
