import { Link } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";
import { useEffect, useRef } from "react";

const CallToAction = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const mainControls = useAnimation();
  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    } else {
      mainControls.start("hidden");
    }
  }, [isInView, mainControls]);
  return (
    <motion.section id="cta" className="">
      {/* Flex Container */}

      <div className="container  grid md:grid-cols-3 xl:gap-20 gap-4  px-6 py-24 mx-auto space-y-12 md:py-12  md:space-y-0">
        <div className="bg-primary py-10 px-10 rounded-lg border-2 border-black text-center">
          <h2 className="text-xl md:font-semibold  leading-tight text-center text-white md:text-2xl md:max-w-xl md:text-left  ">
            Trusted By 10000+ Students
          </h2>
        </div>
        <div className="bg-primary py-10 px-10 rounded-lg border-2 border-black text-center">
          <h2 className="text-xl md:font-semibold  leading-tight text-center text-white md:text-2xl md:max-w-xl md:text-left  ">
            95% course completion rate
          </h2>
        </div>
        <div className="bg-primary py-10 px-10 rounded-lg border-2 border-black text-center">
          <h2 className="text-xl md:font-semibold  leading-tight text-center text-white md:text-2xl md:max-w-xl md:text-left  ">
            4.8 / 5 rating from graduates
          </h2>
        </div>

        {/* <div>
          <Link
            to="#"
            className="p-3 px-6 pt-2 text-brightRed bg-white rounded-full shadow-2xl baseline hover:bg-gray-900"
          >
            Get Started
          </Link>
        </div> */}
      </div>
    </motion.section>
  );
};

export default CallToAction;
