import React, { useRef, useState } from "react";
import "react-phone-input-2/lib/plain.css";

import "./form.css";
import "./telephone.css";
import PhoneInput from "react-phone-input-2";

const ZohoForm = () => {
  const formRef = useRef(null);
  const [payload, setPayload] = useState({
    Email: "",
    Phone: "",
    Last_Name: "",
    countrycode: "",
  });

  const [successModal, setSuccessModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleOnChange = (e) => {
    const { value, id } = e.target;
    setPayload({ ...payload, [id]: value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    if (loading) return;
    const data = {
      email: payload.Email,
      phone: payload.Phone,
      fullname: payload.Last_Name,
      countrycode: payload.countrycode || "+234",
    };
    // const response = await dispatch(registerUser(data));
    setLoading(false);

    //submit data to backend
    // if (response?.payload?.success === true) {
    //   // toast.success(
    //   //   "Registration Successful, Please Check your mail for Login Information"
    //   // );

    //   setSuccessModal(true);
    //   setTimeout(() => {
    //     formRef.current.submit();
    //   }, 3000);
    //   return;
    // }

    // if (!response.payload?.data?.success) {
    //   // toast.warn(response.payload?.data?.error);

    //   return;
    // }
    // toast.warn(response.payload?.data?.error);
  };
  return (
    <div>
      <div className="HomeSection1_area_2_form_div">
        <div className="HomeSection1_area_2_form_div_title">
          Fill the form to know more
        </div>
        <div className="HomeSection1_area_2_form_div_body">
          <form
            ref={formRef}
            action="https://crm.zoho.eu/crm/WebToLeadForm"
            name="WebToLeads639378000000718001"
            method="POST"
            // onSubmit={() => {
            //   javascript: document.charset = "UTF-8";
            //   return checkMandatory639378000000718001();
            // }}
            acceptCharset="UTF-8"
          >
            <input
              type="text"
              // style="display:none;"
              style={{ display: "none" }}
              name="xnQsjsdp"
              value="82c910ebe955a0a37a4be1052ae7587e2fd5dfaa687401529c349600be097859"
            />
            <input type="hidden" name="zc_gad" id="zc_gad" value="" />
            <input
              type="text"
              // style="display:none;"
              style={{ display: "none" }}
              name="xmIwtLD"
              value="30f2d76b6e4059f38f3c6fe3c14c56364df5d3a716cc7b7b274b56d3b5f7ef039f49694235ce05ea494ca61e269a0814"
            />
            <input
              type="text"
              // style="display:none;"
              style={{ display: "none" }}
              name="actionType"
              value="TGVhZHM="
            />
            <input
              type="text"
              // style="display:none;"
              style={{ display: "none" }}
              name="returnURL"
              value="https&#x3a;&#x2f;&#x2f;trademindshub.com"
            ></input>

            <input
              type="text"
              id="Last_Name"
              name="Last Name"
              value={payload.Last_Name}
              onChange={handleOnChange}
              maxLength="80"
              placeholder="Full Name *"
              className="HomeSection1_area_2_form_div_body_input"
            />
            <input
              type="text"
              ftype="email"
              autoComplete="false"
              id="Email"
              name="Email"
              maxLength="100"
              placeholder="Email *"
              value={payload.Email}
              onChange={handleOnChange}
              className="HomeSection1_area_2_form_div_body_input"
            />

            <PhoneInput
              country={"ng"}
              enableSearch={true}
              placeholder="Phone Number  *"
              containerClass=""
              value={payload.Phone}
              onChange={(value, country, e, formattedValue) => {
                setPayload({
                  ...payload,
                  countrycode: "+" + country.dialCode,
                  Phone: value,
                });
              }}
            />
            <input
              type="text"
              id="Phone"
              name="Phone"
              maxLength="30"
              value={payload.Phone}
              onChange={handleOnChange}
              placeholder="Phone Number *"
              hidden
              className="HomeSection1_area_2_form_div_body_input"
            />

            <div className="HomeSection1_area_2_form_div_button_div">
              <button
                onClick={handleSubmit}
                type="button"
                className="HomeSection1_area_2_form_div_button flex gap-3 items-center justify-center"
                id="formsubmit"
                value="Submit"
                title="  I want to know more"
              >
                I want to learn more
                {loading && <i class="fas fa-spinner fa-spin"></i>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ZohoForm;
