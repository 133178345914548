import { BrowserRouter } from "react-router-dom";

import Navbar from "./components/Navbar.jsx";
import Hero from "./components/Hero.jsx";
import Features from "./components/Features.jsx";
import Testimonial from "./components/Testimonial.jsx";
import CallToAction from "./components/CallToAction.jsx";
import Footer from "./components/Footer.jsx";
import Steps from "./components/Steps.jsx";
import Features2 from "./components/Features2.jsx";
import "@smastrom/react-rating/style.css";
import Contact from "./components/Contacts/index.jsx";
import { motion } from "framer-motion";
import { fadeIn } from "./utils/variants.jsx";
import { transition } from "./utils/transition.jsx";

function App() {
  return (
    <BrowserRouter>
      <Navbar />

      <Hero />

      {/* <Steps /> */}
      <Features />
      <Features2 />
      <Testimonial />
      <CallToAction />
      <Contact />

      <Footer />
    </BrowserRouter>
  );
}

export default App;
