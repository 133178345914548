import { Container } from "@mui/material";
import React, { useState } from "react";
import "./contact.css";
import axios from "axios";
import toast, { ToastBar, Toaster } from "react-hot-toast";

const Contact = () => {
  const [payload, setPayload] = useState({
    fullname: "",
    phoneNumber: "",
    email: "",
    content_message: "",
    countrycode: "+234",
  });

  const signUpUser = async (e) => {
    e.preventDefault();

    console.log(payload, "payload");

    if (
      !payload.email === "" ||
      !payload.phoneNumber === "" ||
      !payload.fullname === ""
    )
      return;

    try {
      const res = await axios.post(
        "https://back-office.trademindshub.com/pub/v1/user/register",
        {
          email: payload.email,
          fullname: payload.fullname,
          countrycode: payload.countrycode,
          phone: payload.phoneNumber,
        }
      );
      console.log(res, "response");
      if (!res?.success) {
        console.log("wrong");
        toast("You Have Already Submitted A Request!!!");
        return;
      }

      toast.success("Your response has been recieved!!");
    } catch (error) {
      console.log("an error occured");
      toast.error("an error occured!!!");
    }
  };

  const handleOnchange = (e) => {
    const { value, id } = e.target;

    setPayload({ ...payload, [id]: value });
  };
  return (
    <div>
      <section className="contact_section">
        <Toaster />
        <Container>
          <div className="md:flex-row flex flex-col">
            <div className="contact_section_area_1">
              <div className="contact_section_area_1_cont1">
                <div className="contact_section_area_1_cont1_title">
                  ADDRESS:
                </div>
                <div className="contact_section_area_1_cont1_para">
                  Plot 157 Tom Inkotaria Avenue, Rumuogba Housing Estate, Port
                  Harcourt, Rivers State
                </div>
              </div>

              <hr />

              <div className="contact_section_area_1_cont1">
                <div className="contact_section_area_1_cont1_title">
                  Phone Number:
                </div>
                <div className="contact_section_area_1_cont1_para">
                  +2348116106891
                </div>
              </div>

              <hr />

              <div className="contact_section_area_1_cont1">
                <div className="contact_section_area_1_cont1_title">
                  E-MAIL:
                </div>
                <div className="contact_section_area_1_cont1_para">
                  support@Trademindshub.com
                </div>
              </div>
            </div>
            <div className="contact_section_area_2 px-5">
              <div className="contact_section_area_2_title">Contact Form</div>
              <div className="contact_section_area_2_body">
                <div className="contact_section_area_2_body_1">
                  <input
                    type="text"
                    className="contact_section_area_2_body_1_inputdiv1"
                    placeholder="Full Name"
                    id="fullname"
                    value={payload.fullname}
                    onChange={handleOnchange}
                  />
                  <input
                    type="text"
                    className="contact_section_area_2_body_1_inputdiv1"
                    placeholder="Phone Number"
                    id="phoneNumber"
                    value={payload.phoneNumber}
                    onChange={handleOnchange}
                  />
                </div>
                <div className="contact_section_area_2_body_2">
                  <input
                    type="text"
                    className="contact_section_area_2_body_1_inputdiv1"
                    placeholder="Email Address"
                    value={payload.email}
                    onChange={handleOnchange}
                    id="email"
                  />
                </div>
                <div className="contact_section_area_2_body_3">
                  <textarea
                    name=""
                    id="contact-message"
                    cols={30}
                    rows={10}
                    placeholder="Your Message"
                    className="contact_section_area_2_body_3_input"
                  ></textarea>
                </div>
                <button
                  onClick={signUpUser}
                  className=" bg-brightRed py-3 my-5  rounded-lg"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Contact;
